export class Configuration {
  static get() {
    if (window.location.hostname.indexOf('pendaftaranrspolri') > -1) {
      return {
        apiBackend: 'http://202.51.105.98:9000/service/',
        headerToken: 'X-AUTH-TOKEN',
        authLogin: ':9000',
      }
    } else if (window.location.hostname.indexOf('localhost') > -1) {
      return {
        apiBackend: 'http://localhost:8000/service/',
        headerToken: 'X-AUTH-TOKEN',
        authLogin: ':8000',
      }
    } else if (window.location.hostname.indexOf('202.51.105.98') > -1) {
      return {
        apiBackend: 'http://202.51.105.98:9000/service/',
        headerToken: 'X-AUTH-TOKEN',
        authLogin: ':9000',
      }
    } else {
      return {
        apiBackend: '/service/',
        headerToken: 'X-AUTH-TOKEN',
        authLogin: ':8000',
      }
    }
  }
}
